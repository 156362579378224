define("discourse/plugins/discourse-subscriptions/discourse/models/user-subscription", ["exports", "@ember/object", "discourse/lib/ajax", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-subscriptions/discourse/models/plan"], function (_exports, _object, _ajax, _decorators, _I18n, _plan) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const UserSubscription = _object.default.extend((_dec = (0, _decorators.default)("status"), _dec2 = (0, _decorators.default)("current_period_end", "canceled_at"), _dec3 = (0, _decorators.default)("discount"), _dec4 = (0, _decorators.default)("plan.metadata.is_system_recurring"), _dec5 = (0, _decorators.default)("plan.metadata.is_system_recurring"), (_obj = {
    canceled(status) {
      return status === "canceled";
    },
    endDate(current_period_end, canceled_at) {
      if (!canceled_at) {
        return moment.unix(current_period_end).format("LL");
      } else {
        return _I18n.default.t("discourse_subscriptions.user.subscriptions.cancelled");
      }
    },
    discounted(discount) {
      if (discount) {
        const amount_off = discount.coupon.amount_off;
        const percent_off = discount.coupon.percent_off;
        if (amount_off) {
          return `${parseFloat(amount_off * 0.01).toFixed(2)}`;
        } else if (percent_off) {
          return `${percent_off}%`;
        }
      } else {
        return _I18n.default.t("no_value");
      }
    },
    destroy() {
      return (0, _ajax.ajax)(`/s/user/subscriptions/${this.id}`, {
        method: "delete"
      }).then(result => UserSubscription.create(result));
    },
    isSystemSubscription(recurring) {
      return recurring === 'true';
    },
    isInternallyCancelled(recurring) {
      return this.canceled;
    }
  }, (_applyDecoratedDescriptor(_obj, "canceled", [_dec], Object.getOwnPropertyDescriptor(_obj, "canceled"), _obj), _applyDecoratedDescriptor(_obj, "endDate", [_dec2], Object.getOwnPropertyDescriptor(_obj, "endDate"), _obj), _applyDecoratedDescriptor(_obj, "discounted", [_dec3], Object.getOwnPropertyDescriptor(_obj, "discounted"), _obj), _applyDecoratedDescriptor(_obj, "isSystemSubscription", [_dec4], Object.getOwnPropertyDescriptor(_obj, "isSystemSubscription"), _obj), _applyDecoratedDescriptor(_obj, "isInternallyCancelled", [_dec5], Object.getOwnPropertyDescriptor(_obj, "isInternallyCancelled"), _obj)), _obj)));
  UserSubscription.reopenClass({
    findAll() {
      return (0, _ajax.ajax)("/s/user/subscriptions", {
        method: "get"
      }).then(result => result.map(subscription => {
        subscription.plan = _plan.default.create(subscription.plan);
        return UserSubscription.create(subscription);
      }));
    }
  });
  var _default = _exports.default = UserSubscription;
});